function initialize() {

    var mapOptions = {
        center: {lat: 50.803585, lng: 4.367821},
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    var map = new google.maps.Map(
              document.getElementById("map"),
              mapOptions
            );

    var locations = [
        ['Clerens Legal Partner Brussels', 50.803434, 4.3676081, 1],
    ];

    var infowindow = new google.maps.InfoWindow();

    var marker, i;

    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            map: map
        });

        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }
}

if(typeof google !== 'undefined'){
    google.maps.event.addDomListener(window,'load',initialize);
}
