var $body = jQuery('body'),
    $active = jQuery('.menu').find('.active'),
    activecolor = $active.css('background-color');


$body.append('<div class="bgstripe" style="background: '+activecolor+';"></div>');

var loggedin = ( jQuery('html').hasClass('loggedin') ) ? 32 : 0;


jQuery('.bgstripe').css({
  top: $active.offset().top - loggedin
});